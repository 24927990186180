import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../cms/collections/instructions"

var classNames = require("classnames")

function GetTheFactsAboutVaping(props) {
  var columnClass = classNames({
    column: true,
    "show-phone-only": props.showPhoneOnly,
    "show-phone-only-inverse": !props.showPhoneOnly
  })

  return (
    <div className={columnClass}>
      <h4 style={{ marginTop: props.showPhoneOnly ? "72px" : "" }}>
        Did you know?
      </h4>
      <h5 style={{ marginTop: 0 }}>Get the Facts About Vaping</h5>
      <p>
        Investigators found people who used e-cigarettes were 30% more likely to
        have developed a chronic lung disease, including asthma, bronchitis, and
        emphysema, than nonusers.
      </p>
      <ul>
        <li>
          Combining regular and e-cigarettes more than tripled the risk of
          developing chronic lung diseases, the study found.
        </li>
        <li>
          Vaping can cause physical harm, whether it's chemical burns to lung
          tissue, toxic metals that leave lasting scars on lungs, vitamin E oil
          that clogs the lungs, or even overheated batteries that explode.
        </li>
        <li>
          73% of teens do NOT vape or use any commercial tobacco (that's 7 in
          10).
        </li>
        <li>
          The FDA has found no e-cigarette to be safe and effective in helping
          people quit; in fact, more than half of all adult e-cigarette users
          continue to use regular cigarettes.
        </li>
      </ul>
    </div>
  )
}

const StopTheVapePage = () => {
  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          title="Stop The Vape in Mount Pleasant, SC | Oral Surgery & Dental Implant Specialists South Carolina"
          description="Oral Surgery & Dental Implant Specialists South Carolina is teaming up with Project Save A Mouth to help educate students in Mount Pleasant about the negative health effects of vaping."
        />
        <div className="joshua-tree-content">
          <section
            style={{ paddingBottom: "72px" }}
            className="section top-section white-back"
          >
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column">
                <h1 className="mobile-left">Stop the Vape</h1>
              </div>
            </div>
            <div className="columns is-vcentered">
              <div className="column is-4"></div>
              <div className="column is-13">
                <p className="large">
                  Barefoot Oral & Facial Surgery and Project Save A Mouth have
                  partnered during National Children’s Dental Health Month to
                  help show Mount Pleasant students about the harmful health
                  effects of vaping.
                </p>
              </div>
              <div className="column is-1"></div>
              <div className="column has-text-centered">
                <Button
                  anchor
                  standalone
                  buttonText="Sign Pledge"
                  href="#pledge"
                />
              </div>
              <div className="column is-3"></div>
            </div>
          </section>

          <section
            style={{ paddingTop: "0" }}
            className="body-sections section"
          >
            <div className="columns is-vcentered">
              <div className="column is-3"></div>
              <div className="column">
                <TheaterVideo
                  videoUrl="https://www.youtube.com/watch?v=knagpMeYl_g"
                  controls={true}
                  playing={true}
                  overVideo={true}
                >
                  <ImageMeta
                    className="show-phone-only-inverse"
                    responsive
                    responsiveUseBreakpoints="true"
                    publicId="OSSC/DEV/stop-the-vape-desktop"
                    version="1582136678"
                  />
                  <ImageMeta
                    className="show-phone-only"
                    responsive
                    responsiveUseBreakpoints="true"
                    version="1582136678"
                    publicId="OSSC/DEV/Program-Hero-Mobile"
                  />
                </TheaterVideo>
              </div>
              <div className="column is-3"></div>
            </div>
          </section>

          <section style={{}} className="body-sections section">
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column is-11">
                <h3 className="dangerously-offset-1">Stop The Vape</h3>
                <p className="large">
                  Help us as we work to Stop The Vape throughout coastal South
                  Carolina!
                </p>
                <p className="large">
                  We were so happy to partner with Project Save a Mouth during
                  National Children’s Dental Health Month to Stop the Vape in
                  our region. With your help, we were able to share about the
                  dangers of e-cigarettes and had many students sign our Stop
                  the Vape pledge.
                </p>
                <p className="large">
                  If you didn’t have the opportunity to take our Stop the Vape
                  pledge, please fill out the form below.{" "}
                </p>
                <p className="large">
                  You don’t have to quit by yourself—there are resources to help
                  you take back control of your health. Call{" "}
                  <a href="tel:+18007848669">1-800-QUIT-NOW (1-800-784-8669)</a>{" "}
                  or visit{" "}
                  <a
                    target="_blank"
                    href="https://www.thetruth.com/articles/hot-topic/GameAwards/quit-vaping"
                  >
                    TheTruth.com
                  </a>
                  .
                </p>

                <GetTheFactsAboutVaping showPhoneOnly />
              </div>
              <div className="column is-1"></div>

              <GetTheFactsAboutVaping />

              <div className="column is-3"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

// export const pageQuery = graphql`
//   query instructions {
//     allInstructionsJson {
//       nodes {
//         title
//         name
//         preOrPost
//         youtube
//         instructionBlockHeading
//         order
//       }
//     }
//   }
// `

export default StopTheVapePage
